// Charts.js
import React from 'react';
import { Flex, Button, Text } from "@chakra-ui/react";
import { createDate, convertCurrency, formatDateByMonth } from "../utils/utils";
import ChartWrapper from "./ChartWrapper";
import PieChartComponent from "./PieChartComponent";
import LineChartWithDottedLine from "./LineChartWithDottedLine";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];
const GASTOS_FIJOS_USD = 4900;
const DAYS_PER_PAGE = 30; // Change to the number of days per page

const Charts = ({ data, currency, exchangeRate, startDate, endDate, currentPage, setCurrentPage }) => {
    const filteredData = data.filter(item => {
        const itemDate = createDate(item.attributes.dateDispensed);
        const start = createDate(startDate);
        const end = createDate(endDate);
        return itemDate >= start && itemDate <= end;
    });

    const gastosFijos = convertCurrency(GASTOS_FIJOS_USD * exchangeRate, currency, exchangeRate);

    const dailyData = filteredData.reduce((acc, item) => {
        const date = createDate(item.attributes.dateDispensed);
        const key = formatDateByMonth(date);

        if (!acc[key]) {
            acc[key] = {};
        }

        const dayKey = `${key}-${String(date.getDate()).padStart(2, '0')}`;
        if (!acc[key][dayKey]) {
            acc[key][dayKey] = 0;
        }

        acc[key][dayKey] += convertCurrency(item.attributes.precio, currency, exchangeRate);

        return acc;
    }, {});

    const lineChartData = Object.entries(dailyData).flatMap(([month, days]) => {
        let cumulativeSum = 0;
        return Object.entries(days).map(([day, value]) => {
            cumulativeSum += value;
            return {
                date: day,
                cumulativeDispensas: cumulativeSum,
                gastosFijos: gastosFijos,
            };
        });
    }).sort((a, b) => a.date.localeCompare(b.date));

    const totalPages = Math.ceil(lineChartData.length / DAYS_PER_PAGE);
    const paginatedLineChartData = lineChartData.slice(currentPage * DAYS_PER_PAGE, (currentPage + 1) * DAYS_PER_PAGE);

    const pieChartData = Object.entries(filteredData.reduce((acc, item) => {
        const productType = item.attributes.genetic.data.attributes.productType;
        if (!acc[productType]) acc[productType] = { units: 0, price: 0 };
        acc[productType].units += item.attributes.units;
        acc[productType].price += convertCurrency(item.attributes.precio, currency, exchangeRate);
        return acc;
    }, {})).map(([name, data]) => ({
        name: `${name} (${data.price.toFixed(2)} ${currency})`,
        value: data.units
    }));

    return (
        <>
            <ChartWrapper title="Progreso Diario hacia Gastos Fijos Mensuales">
                <LineChartWithDottedLine
                    data={paginatedLineChartData}
                    xKey="date"
                    lineDataKey="cumulativeDispensas"
                    goalKey="gastosFijos"
                />

            </ChartWrapper>

            <ChartWrapper title="Distribución de Productos Dispensados por Tipo">
                <PieChartComponent
                    data={pieChartData}
                    dataKey="value"
                    nameKey="name"
                    colors={COLORS}
                />
            </ChartWrapper>
        </>
    );
};

export default Charts;
