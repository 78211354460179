import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const login = (newJwt) => {
        setJwt(newJwt);
        localStorage.setItem('jwt', newJwt);
    };

    const logout = () => {
        setJwt(null);
        localStorage.removeItem('jwt');
    };

    return (
        <AuthContext.Provider value={{ jwt, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
