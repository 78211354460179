// PopularityScore.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Heading, Select } from "@chakra-ui/react";
import { convertCurrency } from "../utils/utils";

const PopularityScore = ({ data, productTypes, selectedProductType, setSelectedProductType }) => {
    const popularityScore = data.reduce((acc, item) => {
        const productName = item.attributes.genetic.data.attributes.Name;
        const productType = item.attributes.genetic.data.attributes.productType;
        if (!acc[productName]) {
            acc[productName] = { totalUnits: 0, totalDispenses: 0, type: productType };
        }
        acc[productName].totalUnits += item.attributes.units;
        acc[productName].totalDispenses += 1;
        return acc;
    }, {});

    const popularityScoreData = Object.entries(popularityScore)
        .filter(([_, data]) => selectedProductType === 'All' || data.type === selectedProductType)
        .map(([productName, { totalUnits, totalDispenses }]) => ({
            productName,
            score: totalUnits / totalDispenses
        }))
        .sort((a, b) => b.score - a.score);

    return (
        <Box bg="white" p={5} shadow="md" borderWidth="1px" borderRadius="md">
            <Heading size="md" mb={4}>Popularity Score</Heading>
            <Select value={selectedProductType} onChange={(e) => setSelectedProductType(e.target.value)} mb={4}>
                {productTypes.map(type => <option key={type} value={type}>{type}</option>)}
            </Select>
            <Box height="300px">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={popularityScoreData}>
                        <XAxis dataKey="productName" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="score" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default PopularityScore;
