import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, useColorModeValue, VStack } from "@chakra-ui/react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.eljointclub.com/login', { username, password });
            login(response.data.jwt);
            // Navigate to the page they were trying to access, or to the home page
            const from = location.state?.from?.pathname || "/";
            navigate(from, { replace: true });
        } catch (error) {
            console.error('Login failed:', error);
            // Handle login error, show error message to the user
        }
    };

    const cardBg = useColorModeValue('gray.100', 'gray.700');

    return (
        <Box display="flex" alignItems="center" justifyContent="center" h="100vh">
            <Box bg={cardBg} p={8} borderRadius="lg" boxShadow="md" w={['90%', '70%', '50%', '30%']}>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel htmlFor="username">Username</FormLabel>
                            <Input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormControl>
                        <Button type="submit" colorScheme="blue" width="full" size="lg">
                            Login
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Box>
    );
};

export default Login;
