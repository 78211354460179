export const fetchExchangeRate = async (setExchangeRate, setError) => {
    try {
        const response = await fetch('https://api.bluelytics.com.ar/v2/latest');
        const data = await response.json();
        setExchangeRate(data.blue.value_avg);
    } catch (error) {
        console.error('Error fetching exchange rate:', error);
        setError(error);
    }
};

export const fetchAllPages = async (jwt, setError) => {
    if (!jwt) {
        setError(new Error('No authentication token found'));
        return [];
    }

    let allData = [];
    let currentApiPage = 0;
    let hasMoreData = true;
    const API_PAGE_SIZE = 100;

    while (hasMoreData) {
        try {
            const response = await fetch(`https://admin.eljointclub.com/api/dispense-histories?populate[user][fields][0]=username&populate[user][fields][1]=email&populate=genetic&pagination[pageSize]=${API_PAGE_SIZE}&pagination[page]=${currentApiPage}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            const result = await response.json();
            const pageData = result.data;
            allData = [...allData, ...pageData];
            currentApiPage++;
            hasMoreData = pageData.length === API_PAGE_SIZE;
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
            hasMoreData = false;
        }
    }
    return allData;
};
