// utils.js
export const formatDate = (date) => {
    return date.toISOString().split('T')[0];
};

export const createDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
};

export const convertCurrency = (value, currency, exchangeRate) => {
    return currency === 'USD' ? value / exchangeRate : value;
};

export const formatDateByMonth = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
};
