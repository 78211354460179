import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, VStack, Heading, Flex, Button, Text, Input, Select } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import Charts from "./components/Charts";
import PopularityScore from "./components/PopularityScore";
import { fetchExchangeRate, fetchAllPages } from "./utils/api";
import { formatDate } from "./utils/utils";
import { useAuth } from './context/AuthContext';

const Panel = () => {
    const { jwt, logout } = useAuth();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [currency, setCurrency] = useState('ARS');
    const [exchangeRate, setExchangeRate] = useState(0);
    const [productTypes, setProductTypes] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState('All');
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [startDate, setStartDate] = useState(formatDate(firstDayOfMonth));
    const [endDate, setEndDate] = useState(formatDate(today));

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchExchangeRate(setExchangeRate, setError);
                const allData = await fetchAllPages(jwt, setError);
                setData(allData);
                const types = [...new Set(allData.map(item => item.attributes.genetic.data.attributes.productType))];
                setProductTypes(['All', ...types]);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [jwt]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    if (loading) return <Box>Cargando...</Box>;
    if (error) return <Box>Error: {error.message}</Box>;

    return (
        <>
            <VStack spacing={8} align="stretch" p={5}>
                <Flex justify="space-between" align="center">
                    <Heading size="lg">Panel</Heading>
                    <Button onClick={handleLogout}>Logout</Button>
                </Flex>
                <Box bg="white" p={5} shadow="md" borderWidth="1px" borderRadius="md">
                    <Heading size="md" mb={4}>Configuración</Heading>
                    <Flex justify="space-between">
                        <Box flex="1" mr={4}>
                            <Text mb={2}>Fecha de inicio</Text>
                            <Input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Box>
                        <Box flex="1" mr={4}>
                            <Text mb={2}>Fecha de fin</Text>
                            <Input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Box>
                        <Box flex="1">
                            <Text mb={2}>Moneda</Text>
                            <Select
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                            >
                                <option value="ARS">ARS</option>
                                <option value="USD">USD</option>
                            </Select>
                        </Box>
                    </Flex>
                    <Text mt={4}>
                        Tasa de cambio actual (Blue): 1 USD = {exchangeRate.toFixed(2)} ARS
                    </Text>
                </Box>
                <Charts
                    data={data}
                    currency={currency}
                    exchangeRate={exchangeRate}
                    startDate={startDate}
                    endDate={endDate}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                <PopularityScore
                    data={data}
                    productTypes={productTypes}
                    selectedProductType={selectedProductType}
                    setSelectedProductType={setSelectedProductType}
                />
            </VStack>
        </>
    );
};

export default Panel;
