// LineChartWithDottedLine.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LineChartWithDottedLine = ({ data, xKey, lineDataKey, goalKey }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <XAxis dataKey={xKey} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={lineDataKey} stroke="#8884d8" dot={{ r: 4 }} activeDot={{ r: 6 }} />
                <Line type="monotone" dataKey={goalKey} stroke="#82ca9d" strokeDasharray="5 5" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineChartWithDottedLine;
