// ChartWrapper.js
import React from 'react';
import { Box, Heading } from "@chakra-ui/react";

const ChartWrapper = ({ title, children }) => {
    return (
        <Box bg="white" p={5} shadow="md" borderWidth="1px" borderRadius="md">
            <Heading size="md" mb={4}>{title}</Heading>
            <Box height="300px">
                {children}
            </Box>
        </Box>
    );
};

export default ChartWrapper;
